// Bring in SCSS for compiling and output
import '../styles/main.scss';
import 'plyr/dist/plyr.css';

// Import JS modules
import Menu from './menu';
import ReadingTime from '@aquent/web-reading-time';
import Splide from '@splidejs/splide';
import Plyr from 'plyr';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import HeroVideo from './hero-video';
import RibbonGallery from './ribbon-gallery';
import PartnershipTabbsContoller from './acf-blocks/partnership-tabs-controller';
import PlayOnHover from './play-on-hover';
import FilterBar from './filter-bar';
import AccordionInit from './accordion';
import LargeTextSeparatorInit from './large-text-separator';
import Noise from './noise';
import VideoControllerInit from './video-controller';
import TabPanel from './acf-blocks/tab-panel';

// Initialize interactive elements
//TODO: Refactor this to a class
/**
 * Audio
 */
const player = new Plyr('#player', {
  controls: [
    'play',
    'current-time',
    'duration',
    'progress',
    /// You can add more controls as many as you want ///
  ],
  invertTime: false,
});

if (document.querySelector('.section-audio') != null) {
  const audioElem = document.querySelector('.section-audio');
  const stickyaudio = audioElem.getBoundingClientRect();
  const fixedClass = 'audio--fixed';
  const hideAudio = document.getElementById('hide-audio');
  const AudioOffsetTop = stickyaudio.top + window.pageYOffset;

  let updating = false;

  const handleScroll = () => {
    if (AudioOffsetTop < window.pageYOffset) {
      audioElem.classList.add(fixedClass);
      hideAudio.onclick = () => {
        audioElem.style.visibility = 'hidden';
        audioElem.style.opacity = '0';
        player.pause();
      };
      updating = true;
    } else {
      audioElem.classList.remove(fixedClass);
      audioElem.style.visibility = 'visible';
      audioElem.style.opacity = '1';
      updating = false;
    }
  };
  // on scroll, if an update opportunity is available, update

  player.on('play', (event) => {
    window.onscroll = () => {
      handleScroll();
      updating = true;
    };

    const instance = event.detail.plyr;
    const progress = ((instance.currentTime / instance.duration) * 100).toFixed(0);

    tryToSubmitEvent('audio_play', {
      source: instance.source,
      current_time: instance.currentTime.toFixed(0),
      progress,
    });
  });

  player.on('pause', (event) => {
    window.onscroll = () => {
      if (updating = false || AudioOffsetTop > window.pageYOffset) {
        audioElem.classList.remove(fixedClass);
        audioElem.style.visibility = 'visible';
        audioElem.style.opacity = '1';
      }
    };

    const instance = event.detail.plyr;
    const progress = ((instance.currentTime / instance.duration) * 100).toFixed(0);

    if (!instance.ended) {
      tryToSubmitEvent('audio_paused', {
        source: instance.source,
        current_time: instance.currentTime.toFixed(0),
        progress,
      });
    }
  });

  // Sent when playback completes.
  player.on('ended', (event) => {
    const instance = event.detail.plyr;

    tryToSubmitEvent('audio_ended', {
      source: instance.source,
    });
  });

  const tryToSubmitEvent = (eventName, eventParams) => {
    try {
    // eslint-disable-next-line no-undef
      gtag('event', eventName, eventParams);
    } catch (e) {
    // Uncaught error. Google analytics couldn't be loaded on this page. Mostly due to cookie compliance or ad blockers.
    }
  };
}

const heroVideo = new HeroVideo();

const sliderGallery = document.querySelector('.o-ribbon-gallery')
  ? new Splide('.splide.o-ribbon-gallery', {
    type: 'loop',
    drag: 'free',
    snap: false,
    autoScroll: {
      speed: 0.1,
    },
  }).mount({ AutoScroll })
  : null;

const ribbonGallery = new RibbonGallery('.o-ribbon-gallery');

const readingTime = document.querySelector('.single-post') ? new ReadingTime('.entry-content') : null;
const noise = new Noise();
grained('.noise', {
  grainOpacity: 0.1,
  grainDensity: 1,
});
grained('.noise-low', {
  grainOpacity: 0.05,
  grainDensity: 1,
});

const partnershipTabbsContoller = new PartnershipTabbsContoller('.tabs');

Menu();
VideoControllerInit();
AccordionInit();
LargeTextSeparatorInit();
PlayOnHover();
FilterBar();
TabPanel();
